import "react-toastify/dist/ReactToastify.css";
import "@recx/auth/dist/index.css";
import "@recx/widgets/dist/index.css";

import App from "./App";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<App />);
