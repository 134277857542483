import { AuthContainer } from "@recx/auth";
import { HttpProvider } from "@recx/auth";
import loader from "assets/images/loader.gif";
import logo from "assets/images/logo.svg";
import { datadogRum } from "@datadog/browser-rum";
import Home from "screens/Home";
import { QueryClientContainer } from "@recx/widgets";

function App() {
  let Originurl = window.location.origin.toString();

  let base_api = process.env.REACT_APP_API_URL;

  if (!Originurl.includes("localhost")) {
    datadogRum.init({
      applicationId: "f294cd50-d2e6-4f15-abbd-837f3c0e7614",
      clientToken: "pubc6b31e525894ad219b17d957df50d7a0",
      site: "datadoghq.com",
      service: process.env.REACT_APP_NAME,
      env: process.env.REACT_APP_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });
  }
  return (
    <AuthContainer
      {...{
        baseUrl: `/`,
        client: "SSO",
        identityServerUrl: process.env.REACT_APP_Identity_URL,
        logo,
        loader,
        baseOriginUrl: process.env.REACT_APP_BASE_URL,
      }}
    >
      <QueryClientContainer>
        <HttpProvider apiUrl={base_api}>
          <Home />
        </HttpProvider>
      </QueryClientContainer>
    </AuthContainer>
  );
}

export default App;
