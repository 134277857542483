import {
  AccessDenied,
  Header,
  Layout,
  MiniSideBar,
  Outlet,
  useAuthContext,
} from "@recx/auth";
import Loader from "components/Loader";
import React from "react";
import { toast, ToastContainer } from "react-toastify";

const DashboardPages = [
  {
    icon: "fal fa-poll-h",
    name: "Dashboard",
    route: "dashboard",
    component: React.lazy(() => import("./Apps/Dashboard")),
    unique_id: "component_recx",
    show: true,
    child: false,
    showInSidebar: false,
    children: [],
    description:
      "Detail of completed or partial completed responses against currently active and completed campaigns",
    path: "/dashboard",
  },
];

const modules = ["feedback", "converse", "analyze"];
function Home() {
  const { SideBarItems, routes, loading, permissionEvaluated } =
    useAuthContext();

  return (
    <>
      {loading && <Loader />}
      <Header
        {...{
          toast,
          baseOriginUrl: process.env.REACT_APP_BASE_ORIGIN_URL,
          menuItems: [],
        }}
      ></Header>
      <MiniSideBar {...{ routes: [] }} />
      {permissionEvaluated && (
        <AccessDenied
          {...{ apps: routes.filter((x) => x.canAccess), loading }}
        />
      )}
      <Layout
        {...{
          modules,
          SideBarDefaultItems: DashboardPages,
          appName: process.env.REACT_APP_TITLE,
        }}
      >
        <Outlet />
        <ToastContainer
          position={"bottom-right"}
          autoClose={2000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
        />
      </Layout>
    </>
  );
}

export default Home;
